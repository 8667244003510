import { createContext, useContext, useReducer } from 'react'
import CMPInit from '../CMPInit'
import useConsentReady from '../../modules/use-consent-ready'

const defaultState = {
  loaded: false,
  consentReady: false
}
export const CMPContext = createContext(defaultState)

const reducer = (state, action) => {
  switch (action.type) {
    case 'loaded':
      return { ...state, loaded: true }
    case 'consentReady':
      return { ...state, consentReady: true }
    default:
      throw Error('Unknown action.');
  }
}

const CMPProvider = ({ children, active = true }) => {
  const [cmpState, dispatch] = useReducer(reducer, defaultState,)
  const consentReady = useConsentReady({ready: cmpState.loaded})
  if (consentReady && !cmpState.consentReady) {
    dispatch({ type: 'consentReady' })
  }

  if (!active) {
    return children
  }

  return (
    <CMPContext.Provider value={cmpState}>
      <CMPInit onSuccess={() => { dispatch({ type: 'loaded' })}} />
      {children}
    </CMPContext.Provider>
  )
}

export const useCMPContext = () => useContext(CMPContext)

export default CMPProvider
