import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Link from '../../../../components/Link'

const SMARTAD = {
  always: 'always',
  smart: 'smart',
  disabled: 'disabled',
}

class ArticleSmartAd {
  constructor(adComponents) {
    this.hasAd = false
    this.hasMobileAd = false
    this.adComponents = adComponents
    this.foundEnd = false
  }

  nodeLength(node, length) {
    if (!this.foundEnd) {
      if (node && (node.name === 'embed' || node.name === 'div' || node.name === 'video-jw')) {
        this.foundEnd = true
        return length
      }
      if (node && node.name === 'br') length += 50
      if (node && node.data) {
        length += node.data.length
      }
      if (node && node.children) {
        //eslint-disable-next-line
        //node.children.map((node) => {
        length = this.nodeLength(node.children[0], length)
        //})
      }
      if (node && node.next) {
        length = this.nodeLength(node.next, length)
      }
    }
    return length
  }

  showDesktopAd({ domNode }) {
    const { type, data, prev, name } = domNode
    if (
      ((type === 'text' && data.length > 2) ||
        (type === 'tag' && name === 'br') ||
        (type === 'tag' && name === 'a' && prev === null)) &&
      !this.hasAd &&
      this.adComponents &&
      this.adComponents.showAds &&
      this.adComponents.smartAd !== SMARTAD.disabled
    ) {
      const dataLength = this.nodeLength(domNode, 0)
      this.foundEnd = false
      const prevHeadline = prev && prev.name === 'embed' && prev.attribs.id.includes('headline')
      if ((!prevHeadline && dataLength > 700) || this.adComponents.smartAd === SMARTAD.always) {
        this.hasAd = true
        return () => (
          <Fragment>
            {this.adComponents.desktop}
            {type === 'tag' && name === 'a' && domNode.children && domNode.attribs.href && domNode.children.length > 0 && (
              <Link autoExternalLinkIcon to={domNode.attribs.href}>
                {domNode.children[0].data}
              </Link>
            )}
            {data}
          </Fragment>
        )
      } else if (type === 'tag' && name === 'br') {
        return () => (
          <Fragment>
            <br />
          </Fragment>
        )
      } else {
        return () => <Fragment>{data}</Fragment>
      }
    }
    return false
  }

  showMobileAd({ domNode, show = false }) {
    if (
      this.adComponents &&
      this.adComponents.showAds &&
      !this.hasMobileAd &&
      this.adComponents.smartAd !== SMARTAD.disabled
    ) {
      const { prev } = domNode

      if (
        (prev && prev.type === 'text' && prev.data.length > 100) ||
        show ||
        this.adComponents.smartAd === SMARTAD.always
      ) {
        this.hasMobileAd = true
        return () => this.adComponents.mobile
      }
      return false
    }
    return false
  }
}

ArticleSmartAd.propTypes = {
  adComponents: PropTypes.shape({
    showAds: PropTypes.bool,
    mobile: PropTypes.node,
    desktop: PropTypes.node,
    smartAd: PropTypes.oneOf(Object.values(SMARTAD)),
  }),
}

export default ArticleSmartAd
export { SMARTAD }
