import HTMLReactParser from 'html-react-parser'
import parserOptions, { SMARTAD } from '../options-html-parser'
import imageConfigs from '../../config/images'
import PropTypes from 'prop-types'

const TRESHOLD = 10

/**
 * parses html and returns react nodes
 * @param htmlContent
 * @param parserProps
 * @returns {ReactElement | ReactElement[] | string}
 */
const htmlParser = (htmlContent, parserProps, tags) => {
  const tagsEmpty = !tags || tags.length === 0
  const taggedContent = tagsEmpty ? htmlContent : tagParser(htmlContent, tags)
  const parsedContent = HTMLReactParser(taggedContent, parserOptions(parserProps))
  return tagsEmpty ? parsedContent : removeLinksInExcess(parsedContent, TRESHOLD)
}

/**
 * parses html and substitutes tag words with <a></a> tags
 * @param unsafe
 * @returns {string}
 */
const escapeHTML = (unsafe) => {
  return unsafe
    .replace(/ü/g, '&uuml;')
    .replace(/ä/g, '&auml;')
    .replace(/ö/g, '&ouml;')
    .replace(/Ä/g, '&Auml;')
    .replace(/Ü/g, '&Uuml;')
    .replace(/Ö/g, '&Ouml;')
    .replace(/ß/g, '&szlig;')
    .replace(/\+/g, '\\+')
}

/**
 * parses html and returns react nodes
 * @param parsedContent
 * @param treshold
 * @returns {ReactElement | ReactElement[] | string}
 */
const removeLinksInExcess = (parsedContent, treshold) => {
  const lessLinksContent = []
  let counter = 0
  const regex = new RegExp('/tag/')
  for (let i = 0; i < parsedContent.length; i++) {
    if (
      typeof parsedContent[i].type === 'function' &&
      parsedContent[i].props &&
      parsedContent[i].props.to &&
      parsedContent[i].props.children
    ) {
      const isLinkTag = regex.test(parsedContent[i].props.to)
      if (isLinkTag) {
        counter++
        if (counter > treshold) {
          lessLinksContent.push(parsedContent[i].props.children)
        } else {
          lessLinksContent.push(parsedContent[i])
        }
      } else {
        lessLinksContent.push(parsedContent[i])
      }
    } else {
      lessLinksContent.push(parsedContent[i])
    }
  }
  return lessLinksContent
}

/**
 * parses html and substitutes tag words with <a></a> tags
 * @param htmlContent
 * @param tags
 * @returns {string}
 */
const tagParser = (htmlContent, tags) => {
  let result = htmlContent
  let newTags = []
  if (tags && tags.length > 0) {
    tags.forEach((tag) => {
      let doubleTags = tags.filter((t) => t.includes(tag))
      if (doubleTags.length > 1) {
        return
      } else {
        newTags.push(tag)
      }
    })
  }
  const existingLinkExpression = /<a[^>]+>.*?<\/a>/g
  const notLinks = result.split(existingLinkExpression)
  const links = result.match(existingLinkExpression)
  let newNotLinks = []
  if (notLinks && notLinks.length > 0) {
    notLinks.forEach((nl) => {
      if (newTags && newTags.length > 1) {
        newTags.forEach((tag) => {
          const escapedTag = escapeHTML(tag)
          const expression = `([^\/<>a-zA-Z])(${escapedTag})([^a-zA-Z\-\+])`
          const regex = new RegExp(expression, 'gm')
          const link = `<a href='/tag/${tag}?utm_campaign=click-on-tag' target='_blank'>${tag}</a>`
          nl = nl.replace(regex, '$1' + link + '$3')
        })
      }
      newNotLinks.push(nl)
    })
  }
  let reconstructedContent = ''
  let i = 0
  let j = 0
  if (htmlContent.length > 2) {
    const startsWithALink = htmlContent.substring(0, 2).match(/<a\s/g)
    if (startsWithALink) {
      for (; i < links.length; i++) {
        reconstructedContent += links[i]
        if (newNotLinks && j < newNotLinks.length) {
          reconstructedContent += newNotLinks[j]
          j++
        }
      }
    } else {
      for (; i < newNotLinks.length; i++) {
        reconstructedContent += newNotLinks[i]
        if (links && j < links.length) {
          reconstructedContent += links[j]
          j++
        }
      }
    }
  }
  return reconstructedContent
}

/**
 * can be used to format bytes into human readable formats - for example 100KB
 * @param bytes
 * @param decimals
 * @returns {string}
 */
const formatBytes = (bytes, decimals) => {
  if (0 === bytes) return '0 Bytes'
  const c = 1024,
    d = decimals || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(bytes) / Math.log(c))
  return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

const toKebabCase = (text) =>
  text &&
  text
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('-')

/**
 * a Class to format images links which typically are provided like so:
 * https://s3-images.stol.it/_images/{type}/{dimensions}/img/2019/11/in-ganz-suedtirol-begann-der-montag-mit-minustemperaturen-2.jpg
 */
class ImageFormat {
  constructor(url) {
    this.url = url
  }

  format(format) {
    const imageConfig = imageConfigs[format]
    const imageLink = this.url.replace('{type}', imageConfig.type)
    const imageWidth = imageConfig.width ? imageConfig.width : ''
    const imageHeight = imageConfig.height ? imageConfig.height : ''
    return imageLink.replace('{dimensions}', imageWidth + 'x' + imageHeight)
  }
}

/**
 * can convert a remote hosted image to a base64 string.
 * @param url
 * @param callback
 */
const imageToBase64 = (url, callback) => {
  if (!url.includes('data:image')) {
    const httpRequest = new XMLHttpRequest()
    httpRequest.onload = () => {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        callback(fileReader.result)
      }
      fileReader.readAsDataURL(httpRequest.response)
    }
    httpRequest.open('GET', url)
    httpRequest.responseType = 'blob'
    httpRequest.send()
  } else {
    callback(url)
  }
}

htmlParser.propTypes = {
  htmlContent: PropTypes.string,
  ...parserOptions.propTypes,
}

formatBytes.propTypes = {
  bytes: PropTypes.number,
  decimals: PropTypes.number,
}

ImageFormat.propTypes = {
  url: PropTypes.string,
  format: PropTypes.string,
}

export { htmlParser, SMARTAD, formatBytes, toKebabCase, ImageFormat, imageToBase64 }
