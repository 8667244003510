import consoleLogger from '../../../../modules/console-logger'
import { getStoredItem, setStoredItem } from '../../../../modules/stored-item'

const abTestResult = ({ id, percentTraffic = 0.5, type = 'request' }) => {
  const [consoleLog] = consoleLogger('AdsLog')
  if (!id) {
    return
  }
  let savedTestResult = getStoredItem(`abTest${id}`, false)
  const newTestResult = createTestResult(percentTraffic)

  consoleLog('abTestResult', 'type: ' + type + ', percentage: ' + percentTraffic)

  if (savedTestResult === null) {
    setStoredItem(`abTest${id}`, newTestResult)
    savedTestResult = newTestResult
  }

  return type === 'session' ? savedTestResult : newTestResult
}

const createTestResult = (percentage) => {
  return Math.random() < percentage ? 'a' : 'b'
}

export default abTestResult
