import { useEffect, useState } from 'react'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'

const useConsentReady = ({ready = false}) => {
  const [tcfAPIReady, setTcfAPIReady] = useState(false)
  const [consentReady, setConsentReady] = useState(false)
  const [consoleLog] = consoleLogger('Sourcepoint')

  useEffect(() => {
    if (ready) {
      const checkIt = setInterval(function() {
        if (typeof (window.__tcfapi) == 'function') {
          clearInterval(checkIt);
          setTcfAPIReady(true);
        }
      }, 100);
      return () => {
        clearInterval(checkIt)
      }
    }
  }, [ready])

  useEffect(() => {
    if (!isServerSide() && tcfAPIReady) {
      const __tcfapi = window.__tcfapi

      if (!__tcfapi) {
        consoleLog('no __tcfapi')
        return false
      }

      __tcfapi('addEventListener', 2, function (tcData, success) {
        consoleLog('eventlistener tcData', tcData)
        consoleLog('eventlistener success', success)
        if (success && (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded')) {
          // do something with tcData.tcString
          consoleLog('CONSENT READY')
          if (!consentReady) {
            setConsentReady(true)
          }
          // remove the ourself to not get called more than once
          __tcfapi(
            'removeEventListener',
            2,
            function (success) {
              consoleLog('eventlistener remove', success)
              consoleLog('eventlistener remove listernerId', tcData.listenerId)
              if (success) {
                // oh good...
                consoleLog('eventlistener removed SUCCESS')
              }
            },
            tcData.listenerId,
          )
        } else {
          consoleLog('NO CONSENT')
          if (consentReady) {
            setConsentReady(false)
          }
        }
      })
    }
    return
  }, [tcfAPIReady])
  consoleLog('consent ready state?', consentReady)
  return consentReady
}

export default useConsentReady
